
import { api } from "@/api/api";
import { ApiGetCourseInvitationDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "CourseInvitesPage",
  components: {
    BaseTableSimple,
    BaseLayout,
  },
  setup() {
    const store = useStore<StoreState>();
    const pendingInvitations = ref<ApiGetCourseInvitationDto[]>([]);

    const router = useRouter();
    const route = useRoute();

    const openNewInvitations = () => {
      router.push({
        name: SingleCourseRouteNames.CourseInvitesNew,
      });
    };

    const loadPendingInvitations = async () => {
      pendingInvitations.value = (await api.course.getCourseInvitations(+route.params.id)).data;
    };

    const deleteInvitation = (invitation: ApiGetCourseInvitationDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.course.deleteInvitationAsync(invitation.courseId, invitation.id);
        await loadPendingInvitations();
      });
    };

    const courseInvitationStatus: Record<string, string> = {
      new: "Ny",
      approved: "Akseptert",
      declined: "Avslått",
    };

    const getCourseInvitationStatus = (status: string) => courseInvitationStatus[status] ?? "Ukjent";

    onMounted(() => {
      loadPendingInvitations();
    });

    return {
      course: computed(() => store.state.courses.course),
      isCourseDone: computed(() => store.state.courses.course.status === CourseStatus.Closed),
      pendingInvitations,
      openNewInvitations,
      getCourseInvitationStatus,
      headers,
      deleteInvitation,
      formatDateTime,
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
    };
  },
});

const headers = [
  { text: "Fornavn", value: "firstName" },
  { text: "Etternavn", value: "lastName" },
  { text: "Handlinger", value: "actions" },
  {
    text: "E-post",
    value: "emailAddress",
  },
  { text: "Mobilnummer", value: "phoneNumber" },
  { text: "Status", value: "status" },
  { text: "Invitasjon sendt", value: "inserted" },
  { text: "Begrenset bruker", value: "createRestrictedUser" },
];
